import React from "react"
import Layout from "../components/Layout"
import aboutme from "../constants/about"
import Title from "../components/Title"
import Image from "gatsby-image"
import SEO from "../components/SEO"

const About = () => {
  const data = aboutme
  const [value] = React.useState(0)
  const {title,info,image,stack} = data[value]
  return ( 
    <Layout>
      <SEO title="About" description="about redjam" />
      <section className="about-page">
        <div className="section-center about-center">
          <Image fluid={image.childImageSharp.fluid} className="about-img" />
          <article className="about-text">
            <Title title={title} />
            <p>{info}</p>
            <div className="about-stack">
              {stack.map(item => {
                return <span key={item.id}>{item.title}</span>
              })}
            </div>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export default About