export default [
    {
        "title": "About Red Jam",
        "info": "A creative web designer who aims to bring your project to life at a level of excellence. While I am based in Cleveland, Ohio, I work with businesses from all around the world.",
        "image": {
            "childImageSharp": {
                "fluid": {
                    "src": 
                    '/hero-img2.png'
                }
            }
        },
        "stack": [
            {
                "id": "1",
                "title": "html"
            },
            {
                "id": "2",
                "title": "css"
            },
            {
                "id": "3",
                "title": "javascript"
            },
            {
                "id": "4",
                "title": "react"
            },
            {
                "id": "5",
                "title": "SEO"
            },
        ],
    }
]
